export const generateMicroramImage = (size, extraCupula, extraBase, extraXs) => {
  switch (size) {
    case "60x40":
      switch (extraCupula) {
        case "cupula-com-exaustor":
          switch (extraBase) {
            case "estrutura":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756873/Configurador-Microram/Finais/Microram_2D_MICRORAM_C%C3%9APULA_ESTRUTURA_nffqx7.svg"

            case "estufa":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756875/Configurador-Microram/Finais/Microram_2D_MICRORAM_C%C3%9APULA_ESTUFA_txn6qo.svg"

            case "sem-extras":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756873/Configurador-Microram/Finais/Microram_2D_MICRORAM_C%C3%9APULA_l1kksp.svg"
          }

        case "sem-extras":
          switch (extraBase) {
            case "estrutura":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756873/Configurador-Microram/Finais/Microram_2D_MICRORAM_-_ESTRUTURA_hv9hvd.svg"

            case "estufa":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756873/Configurador-Microram/Finais/Microram_2D_MICRORAM_-_ESTUFA_izqzau.svg"

            case "sem-extras":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756875/Configurador-Microram/Finais/Microram_2D_MICRORAM_ga4fyh.svg"
          }
      }

    case "46x33":
      switch (extraXs) {
        case "estrutura":
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756873/Configurador-Microram/Finais/Microram_2D_MICRORAM_XS__ESTRUTURA_l5ww7b.svg"

        case "sem-extras":
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623756875/Configurador-Microram/Finais/Microram_2D_MICRORAM_XS_kctunc.svg"
      }
  }
}
